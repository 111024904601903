.menu {
  text-align: end;
  padding: 10px 10px 0 10px;
}

.header {
  margin-bottom: 35vh !important;
}

.fancy-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fancy-scroll::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.fancy-scroll::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 7.5px;
}

/* Handle on hover */
.fancy-scroll::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.fake-link {
  color: #2a71c3;
}

.fake-link:hover {
  cursor: pointer;
}

/* .carousel-text {
  font-size: 1.25em;
  font-weight: 350;
  color: #fff;
  text-align: center;
} */

.menu-item {
  color: #fff;
  font-size: medium;
}

.menu-item:hover {
  color: #92a6ff;
}

.logo {
  font-family: "Fredoka One", cursive;
  text-align: center;
  font-size: 35px;
  margin: 16px !important;
  color: #fff;
  opacity: 1;
}

.logo-dark {
  font-family: "Fredoka One", cursive;
  text-align: center;
  font-size: 40px;
  color: #2a71c3;
  opacity: 1;
}

.logo-menu {
  position: absolute !important;
  left: 0 !important;
  top: 10px !important;
}

.logo-menu-side {
  position: absolute !important;
  left: 35px !important;
  top: 20px !important;
}

.logo-menu:hover {
  cursor: pointer;
}

.flex-home {
  flex-grow: 1;
}

.registration-card {
  width: 50vw;
  margin-left: 25vw;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .bg {
    background-size: 100vh;
    background-attachment: fixed;
  }
}

.gradient-bg {
  background-image: linear-gradient(to bottom right, #050b3a, #030e61);
}

.accent {
  color: #e83855;
}

.accent-outline {
  outline-color: #e83855;
  border-color: #e83855;
}

.accent-bg {
  background-color: #e83855;
}

.accent2 {
  color: #f86f1b;
}

.accent2-outline {
  outline-color: #f86f1b;
  border-color: #f86f1b;
}

.accent2-bg {
  background-color: #f86f1b;
}

/*Nav bar css*/
/*

.menuBar{
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
}
.logo{
  width: 150px;
  float: left;
}
.logo a{
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}
.menuCon{
  width: calc(100% - 150px);
  float: left;
}
.menuCon .ant-menu-item{
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title{
  padding: 10px 20px;
}
.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal{
  border-bottom: none;
}
.menuCon .leftMenu{
  float: left;
}
.menuCon .rightMenu{
  float: right;
}
.barsMenu{
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}
.barsBtn{
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.barsBtn:after,.barsBtn:before{
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
.barsBtn:after{
  top: auto;
  bottom: -6px;
}
.ant-drawer-body{
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu{
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal{
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover{
  border-bottom-color: transparent;
}

@media(max-width: 767px){
  .barsMenu{
    display: inline-block;
  }
  .leftMenu,.rightMenu{
    display: none;
  }
  .logo a{
    margin-left: -20px;
  }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title{
    padding: 1px 20px;
  }
  .logo a{
    padding: 10px 20px;
  }
} */
