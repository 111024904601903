.logo {
    height: 32px;
    /* background: rgba(255, 255, 255, 0.2); */
    margin: 16px;
}

.company-layout .company-layout-background {
    background: #fff !important;
    height: 100vh;
}

.edit-profile {
    width: 100%;
    text-align: center;
}

.edit-profile-card {
    width: 50% !important;
    text-align: center;
}
